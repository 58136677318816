import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

const Subtitle = _styled2("h1", process.env.NODE_ENV === "production" ? {
  target: "e4445270"
} : {
  target: "e4445270",
  label: "Subtitle"
})(process.env.NODE_ENV === "production" ? {
  name: "151adhq",
  styles: "display:none;font-size:1.25rem;line-height:1.75rem;font-weight:600;--tw-text-opacity:1;color:rgba(var(--colors-neutral-700), var(--tw-text-opacity));:is([data-theme=\"dark\"] &){--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));}@media (min-width: 768px){display:block;}@media (min-width: 1024px){font-size:1.5rem;line-height:2rem;}"
} : {
  name: "151adhq",
  styles: "display:none;font-size:1.25rem;line-height:1.75rem;font-weight:600;--tw-text-opacity:1;color:rgba(var(--colors-neutral-700), var(--tw-text-opacity));:is([data-theme=\"dark\"] &){--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));}@media (min-width: 768px){display:block;}@media (min-width: 1024px){font-size:1.5rem;line-height:2rem;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL21vZHVsZXMvSGVscC9jb21wb25lbnRzL1N1YnRpdGxlLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFaUIiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvbW9kdWxlcy9IZWxwL2NvbXBvbmVudHMvU3VidGl0bGUudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuY29uc3QgU3VidGl0bGUgPSB0dy5oMWBcbiAgICBoaWRkZW4gbWQ6YmxvY2tcbiAgICBsZzp0ZXh0LTJ4bCB0ZXh0LXhsIGZvbnQtc2VtaWJvbGQgdGV4dC1uZXV0cmFsLTcwMCBkYXJrOnRleHQtd2hpdGVcbmBcblxuZXhwb3J0IGRlZmF1bHQgU3VidGl0bGVcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export default Subtitle;