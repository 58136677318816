import _styled from "@emotion/styled/base";
import React from "react";
import Link from "@components/Link";

const StyledLink = _styled("a", process.env.NODE_ENV === "production" ? {
  target: "eay0zu50"
} : {
  target: "eay0zu50",
  label: "StyledLink"
})("padding:8px 36px;background-color:", ({
  theme
}) => theme.colors.semantic.primary[500], ";border-radius:10px;color:white;font-size:28px;cursor:pointer;@media screen and (max-width: 768px){font-size:12px;padding:6px 20px;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL21vZHVsZXMvSGVscC9jb21wb25lbnRzL0J1dHRvbkxpbmsudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUsyQiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9tb2R1bGVzL0hlbHAvY29tcG9uZW50cy9CdXR0b25MaW5rLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIlxuXG5pbXBvcnQgTGluayBmcm9tIFwiQGNvbXBvbmVudHMvTGlua1wiXG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIlxuXG5jb25zdCBTdHlsZWRMaW5rID0gc3R5bGVkLmFgXG4gICAgcGFkZGluZzogOHB4IDM2cHg7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS5jb2xvcnMuc2VtYW50aWMucHJpbWFyeVs1MDBdfTtcbiAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICBmb250LXNpemU6IDI4cHg7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuXG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgICAgICAgZm9udC1zaXplOiAxMnB4O1xuICAgICAgICBwYWRkaW5nOiA2cHggMjBweDtcbiAgICB9XG5gXG5cbmludGVyZmFjZSBCdXR0b25MaW5rUHJvcHMge1xuICAgIGNoaWxkcmVuPzogUmVhY3QuUmVhY3ROb2RlXG4gICAgaHJlZjogc3RyaW5nXG59XG5jb25zdCBCdXR0b25MaW5rOiBSZWFjdC5GQzxCdXR0b25MaW5rUHJvcHM+ID0gKHsgaHJlZiwgY2hpbGRyZW4gfTogQnV0dG9uTGlua1Byb3BzKSA9PiAoXG4gICAgPExpbmsgaHJlZj17aHJlZn0+XG4gICAgICAgIDxTdHlsZWRMaW5rPntjaGlsZHJlbn08L1N0eWxlZExpbms+XG4gICAgPC9MaW5rPlxuKVxuQnV0dG9uTGluay5kZWZhdWx0UHJvcHMgPSB7XG4gICAgY2hpbGRyZW46IHVuZGVmaW5lZFxufVxuXG5leHBvcnQgZGVmYXVsdCBCdXR0b25MaW5rXG4iXX0= */"));

interface ButtonLinkProps {
  children?: React.ReactNode;
  href: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  children
}: ButtonLinkProps) => <Link href={href}>
        <StyledLink>{children}</StyledLink>
    </Link>;

ButtonLink.defaultProps = {
  children: undefined
};
export default ButtonLink;